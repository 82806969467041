<template>
  <BookingSuccessTemplate
    :headerProps="headerProps"
    :mediaInfo="mediaInfo"
    :content="content"
  />
</template>

<script>
import BookingSuccessTemplate from "@/components/templates/BookingSuccess/BookingSuccess";
import { headerProps } from "@/components/constants";
import heroImg from "@/assets/images/sample-hero.jpg";

export default {
  components: {
    BookingSuccessTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      mediaInfo: {
        img: heroImg,
        isFullScreen: false,
      },
      content: {
        title: "Venue Booked!",
        isScheduling: false,
        startDate: "May 18, 2023",
        endDate: "May 24, 2023",
      },
    };
  },
  methods: {},
};
</script>

<style scoped>
</style>
